<template>
  <admin-fillall-layout>
    <back-button @back-click="back" />
    <div
      id="device-weather-profile"
      class="profile"
    >
      <div class="leftPadding">
        <el-row
          class="header-row"
          type="flex"
          align="center"
          justify="space-between"
        >
          <div>
            <img
              class="thumbnail"
              src="/asserts/icon/weather-station-icon.svg"
            >
          </div>
          <div class="header-row__name tooltip">
            <div class="valueLabel">
              {{ weatherData.name }}
            </div>
            <span class="tooltip-text">{{ weatherData.name }}</span>
            <hr>
          </div>
        </el-row>
        <el-row
          class="topPadding"
          type="flex"
          align="bottom"
          justify="space-between"
        >
          {{ $t('page.device.dialog.inActive') }}<div class="valueLabel">
            {{ !weatherData.inactive?$t('button.on'):$t('button.off') }}
          </div>
        </el-row>
        <el-row style="padding-right:35%;">
          <hr>
        </el-row>
        <el-row
          class="topPadding"
          type="flex"
          align="bottom"
          justify="space-between"
        >
          {{ $t('page.device.dialog.stationID') }}<div class="valueLabel">
            {{ weatherData.station_id }}
          </div>
        </el-row>
        <el-row style="padding-right:35%;">
          <hr>
        </el-row>

        <el-row
          class="topPadding"
          type="flex"
          align="bottom"
          justify="space-between"
        >
          {{ $t('page.device.dialog.gpsLocation') }}
          <div class="valueLabel block__elem">
            {{ $t('page.device.dialog.latitude') }}:{{ weatherData.latitude|roundNumber(7) }}&nbsp;&nbsp;&nbsp;
            {{ $t('page.device.dialog.longitude') }}:{{ weatherData.longitude|roundNumber(7) }}
          </div>
          <div class="valueLabel block__elem--hidden">
            <div class="block__elem--item">
              {{ $t('page.device.dialog.latitude') }}:{{ weatherData.latitude | roundNumber(7) }}
            </div>
            <div class="block__elem--item">
              {{ $t('page.device.dialog.longitude') }}:{{ weatherData.longitude | roundNumber(7) }}
            </div>
          </div>
        </el-row>
        <el-row style="padding-right:35%;">
          <hr>
        </el-row>
        <!-- <el-row class="topPadding" type="flex" align="bottom" justify="space-between">
          Latitude        <div class="valueLabel">{{weatherData.latitude}}</div>
        </el-row>
        <el-row style="padding-right:35%;">
          <hr />
        </el-row>

        <el-row class="topPadding" type="flex" align="bottom" justify="space-between">
          Longitude          <div class="valueLabel">{{weatherData.longitude}}</div>
        </el-row>
        <el-row style="padding-right:35%;">
          <hr />
        </el-row> -->
      </div>
    </div>
  </admin-fillall-layout>
</template>

<script>

import { mapGetters } from 'vuex';
import BackButton from '@/components/Maps/BackButton.vue';

export default {
  components: {
    BackButton,
  },
  data() {
    return {
    };
  },
  computed: {
    ...mapGetters({
      weatherData: 'weather/getWeather',
    }),
  },
  methods: {
    back() {
      this.$router.push({
        name: 'device',
      });
    },
  },
};
</script>

<style lang="scss">
#device-weather-profile{
  width: 100%;
  height: 100%;
  background-color:#f3f3f3;

  .leftPadding{
    position: relative;
    margin-left: 30%;
    top :72px;

    .header-row{
      @include profile-header-row;
      .header-row__name {
        @include profile-header-row__name;
        .valueLabel{
          @include profile-header-row-valueLabel;
          @include profile-valueLabel-ellipsis;
        }
      }
    }

    .first-topPadding{
      margin-top: 52px;
      padding-right:35%;
    }
    .topPadding{
      @include profile-topPadding;
    }
  }
  .valueLabel{
    @include profile-valueLabel;
  }

  .tooltip {
    position: relative;
  }

  .tooltip .tooltip-text {
    @include tooltip-text-style;
    top: 43%;
    left: 66%;
  }

  .tooltip:hover .tooltip-text {
    @include tooltip-hover-style;
  }
  .block__elem {
    @include tablet-medium-860px {
      display: none;
    }
  }
  .block__elem--hidden {
    @include profile-block__elem--hidden;
  }

  .block__elem--item {
    margin-bottom: 7px;
  }

}

</style>
